import React from "react"

const Placeholder = () => null

const FormRegisterLazy = (props) => {
  const [Component, setComponent] = React.useState(() => Placeholder)

  React.useEffect(() => {
    import("../FormRegister").then((FormRegister) =>
      setComponent(() => FormRegister.default)
    )
  }, [])

  return <Component {...props} />
}

export default FormRegisterLazy
