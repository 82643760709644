/** @jsx jsx */
import { jsx, Heading, Text, Flex, Box } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

// import Bio from "../src/components/Bio"
import Section from "../components/Section"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import FormRegisterLazy from "../components/FormRegisterLazy"

const Spacer = () => <span sx={{ mx: 2 }}></span>

class EventTemplate extends React.Component {
  render() {
    const event = this.props.data.mdx

    const siteTitle = event.frontmatter.title
    const { previous, next } = this.props.pageContext
    const eventTimestamp = Date.parse(event.frontmatter.date)
    const todayTimestamp = new Date().getTime()

    let anchor = this.props.location.pathname
      .replace(/\/veranstaltungen\//g, "")
      .replace(/\//g, "")

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={event.frontmatter.title}
          description={event.frontmatter.description || event.excerpt}
        />
        <Section>
          <Link to={"/veranstaltungen#" + anchor}>
            ↰&nbsp;&nbsp;&nbsp;&nbsp;zurück zu Veranstaltungen
          </Link>
          <article sx={{ width: "100%" }}>
            <header sx={{ mt: 4, mb: 3 }}>
              <Text>
                {event.frontmatter.dateFormatted}
                <Spacer />
                {event.frontmatter.time}
                <Spacer />
                {event.frontmatter.location}
              </Text>
              <Heading
                as="h1"
                sx={{ fontSize: [8, 8, 8, 8, 1], lineHeight: 1 }}
              >
                {event.frontmatter.title}
              </Heading>
              {event.frontmatter.subtitle && (
                <Heading as="h2" sx={{ fontSize: 2, lineHeight: 1, mt: 2 }}>
                  {event.frontmatter.subtitle}
                </Heading>
              )}
            </header>
            <Img
              fluid={event.frontmatter.image.childImageSharp.fluid}
              sx={{ mb: 3 }}
            />
            <MDXRenderer>{event.body}</MDXRenderer>
            <hr />
            <footer>{/* <Bio /> */}</footer>
            {event.frontmatter.formRegister &&
              todayTimestamp < eventTimestamp && (
                <FormRegisterLazy
                  eventId={event.frontmatter.eventId}
                  title={event.frontmatter.title}
                  date={event.frontmatter.date}
                  time={event.frontmatter.time}
                  location={event.frontmatter.location}
                  costs={event.frontmatter.costs}
                />
              )}
          </article>

          <nav sx={{ width: "100%", mt: 3 }}>
            <Link to={"/veranstaltungen#" + anchor}>
              ↰&nbsp;&nbsp;&nbsp;&nbsp;zurück zu Veranstaltungen
            </Link>
            <Flex
              sx={{ justifyContent: "space-between", flexWrap: "wrap", mt: 2 }}
            >
              <Box>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </Box>
              <Box>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </Box>
            </Flex>
          </nav>
        </Section>
      </Layout>
    )
  }
}

export default EventTemplate

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 500)
      body
      frontmatter {
        eventId
        title
        subtitle
        location
        date
        dateFormatted: date(formatString: "DD. MMMM YYYY")
        time
        costs
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        formRegister
      }
    }
  }
`
